import * as SDK from 'mirrorfly-sdk';

const SDKIntegration = () => {
  const UserID = process.env.REACT_APP_DEFUALT_USER_ID;
  function connectionListener(response) {
    if (response.status === 'CONNECTED') {
      console.log('Connection Established');
    } else if (response.status === 'DISCONNECTED') {
      console.log('Disconnected');
    } else if (response.status === 'ERROROCCURED') {
      console.log('Erroroccured');
    } else if (response.status === 'CONNECTIONFAILED') {
      console.log('Connection Failed');
    } else if (response.status === 'CONNECTIONTIMEOUT') {
      console.log('Connection Timeout');
    } else if (response.status === 'AUTHENTICATIONFAILED') {
      console.log('Authentication Failed');
    }
  }
  const initSDK = async () => {
    const initializeObj = {
      apiBaseUrl: process.env.REACT_APP_API_URL,
      licenseKey: process.env.REACT_APP_LICENSE_KEY,
      isTrialLicenseKey: true,
      callbackListeners: {
        connectionListener,
        messageListener,
        incomingCallListener,
        callStatusListener,
        userTrackListener,
        muteStatusListener,
        missedCallListener,
        callSwitchListener,
        inviteUsersListener,
        mediaErrorListener,
        callSpeakingListener,
        callUsersUpdateListener,
        callUserJoinedListener,
        callUserLeftListener,
        helper,
      },
    };

    let initSDKResponse = await SDK.initializeSDK(initializeObj);
    console.log(initSDKResponse);
  };

  let userName = '';
  let password = '';

  const registerUser = async () => {
    let userRegistration = await SDK.register(UserID);
    console.log(userRegistration);

    userName = userRegistration.data.username;
    password = userRegistration.data.password;
  };

  const userLogin = async () => {
    let login = await SDK.connect(userName, password);

    console.log(login);
  };

  const textMessage = async () => {
    let msg = 'Message from SDK set up';
    let toUserJid = SDK.getJid(UserID);
    let textMsg = await SDK.sendTextMessage(toUserJid.userJid, msg);
    console.log(textMsg);
  };

  function messageListener(response) {
    console.log('Message Listener', response);
  }

  const incomingCallListener = (res) => {};
  const callStatusListener = (res) => {};
  const userTrackListener = (res) => {};
  const muteStatusListener = (res) => {};
  const missedCallListener = (res) => {};
  const callSwitchListener = (res) => {};
  const inviteUsersListener = (res) => {};
  const mediaErrorListener = (res) => {};
  const callSpeakingListener = (res) => {};
  const callUsersUpdateListener = (res) => {};
  const callUserJoinedListener = (res) => {};
  const callUserLeftListener = (res) => {};
  const helper = {};
  const GetAllUsers = async () => {
    let users = await SDK.getUsersList();
    console.log(users);
  };

  const makeCall = async () => {
    let call = await SDK.makeVoiceCall(
      '254799215837@xmpp-preprod-sandbox.mirrorfly.com'
    );

    console.log(call);
  };
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'column',
        justifyContent: 'center',
      }}
    >
      <button onClick={initSDK}>initialize SDK</button>
      <br />
      <button onClick={registerUser}>Register User</button>
      <br />
      <button onClick={userLogin}>Login User</button>
      <br />
      <button onClick={textMessage}>Send Message</button>

      <br />
      <button onClick={makeCall}>Call Mr. Ezzy</button>

      <br />
      <br />
      <button onClick={GetAllUsers}>All users</button>
    </div>
  );
};

export default SDKIntegration;
