import './App.css';
import SDKIntegration from './reactIntegration';

import { ChatApp } from 'mirrorfly-uikit/dist';
import 'mirrorfly-uikit/dist/assets/scss/bundle.css';

const App = () => {
  const APIURL = process.env.REACT_APP_API_URL;
  const LICENSEKEY = process.env.REACT_APP_LICENSE_KEY;
  const UserID = process.env.REACT_APP_DEFUALT_USER_ID;

  const customColorSet = {
    mfBoxLayoutBg: '#0ab7921f',
    mfThemeColor: '#00a884',
    mfThemeColorBright: '#0ab792',
    mfThemeColorDark: '#008069',
    mfThemeColorRgb: '0,128,105',
    mfHeadingBg: '#008069',
    mfBgcStartchatBannerLink: '#0ab792',
    mfSecondaryDark: '#00a884',
  };

  return (
    <>
      <ChatApp
        licenseKey={LICENSEKEY}
        userIdentifier={UserID}
        apiUrl={APIURL}
        isSandBox={true} // if you are a sandbox user it is true
        theme='dark'
        colorSet={customColorSet}
      />
      <SDKIntegration />
    </>
  );
};
export default App;
